<template>
  <div>
    <nav-bar
      :nav-info="navInfo"
      style="position: fixed; z-index: 1111; width: 100%"
    ></nav-bar>
    <van-search
      v-model.trim="searchValue"
      placeholder="请输入车牌号"
      @search="onSearch"
      style="position: fixed; z-index: 1111; width: 100%; top: 59px"
    >
    </van-search>
    <div id="map"></div>
  </div>
</template>
<script>
import { Toast, Dialog } from "vant";
import NavBar from "../../components/NavBar/NavBar.vue";
import dayjs from "dayjs"
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      navInfo: {
        title: "查询车辆位置",
        left: true,
        path: "/home",
      },
      searchValue: "",
      popupList: [],
      allLayer: [],
      iconUrl: require('../../assets/img/carIcon.png'),
      MultiMarkerMap: null,
      mapData: [], // 车辆轨迹数据
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.MultiMarkerMap = new JDMap.Map("map", {
        zoom: 12,
        fullscreenControl: true,
        detectRetina: true,
      });
    });
  },
  methods: {
    async onSearch(value) {
      for (let i = 0; i < this.allLayer.length; i++) {
        this.allLayer[i].remove()
      }
      for (let i = 0; i < this.popupList.length; i++) {
        this.popupList[i].remove()
      }
      this.popupList = []
      this.allLayer = []
      let res = await this.$Api.realTimeLocWcf(value);
      if (res.data.success) {
        this.mapData = res.data.data ? res.data.data : [];
        if (!this.mapData[0]) {
          Toast("无法获取车辆实时位置");
        }
      } else {
        Toast.fail(res.data.data);
        return false;
      }

      let carTime = dayjs(this.mapData[0].G_time).valueOf()
      if (this.mapData[0].G_time) {
        // 如果车辆位置信息大于5天，则弹出提示
        if (
          new Date().getTime() - 5 * 24 * 3600 * 1000 >
          carTime
        ) {
          Dialog.alert({
            title: "提示",
            message: "当前车辆信息位置可能不准确，建议更换车辆！",
          }).then(() => {});
        }
      } else {
        Dialog.alert({
          title: "提示",
          message: "当前车辆信息位置可能不准确，建议更换车辆！",
        }).then(() => {});
      }

      let time = this.mapData[0].G_time ? this.mapData[0].G_time : '暂无'
      var icon = new JDMap.Icon({
        iconUrl: this.iconUrl,
        iconSize: [50, 30],
      });

      this.MultiMarkerMap.panTo([this.mapData[0].lat, this.mapData[0].lon])
      let markerTo = new JDMap.Marker([this.mapData[0].lat, this.mapData[0].lon], {
        icon,
      });
      markerTo.addTo(this.MultiMarkerMap);

      var popup = new JDMap.Popup({
        offset:new JDMap.Point(2, -30)
      })
      .setLatLng(new JDMap.LatLng(this.mapData[0].lat, this.mapData[0].lon))
      .setContent("<p style='font-size: 12px;'>车辆最近更新时间："+ time +"</p>")
      .openOn(this.MultiMarkerMap);
      
      markerTo.bindPopup(new JDMap.Popup({
        offset: new JDMap.Point(-12, -50),
        keepInView: true,
        content: "<p style='font-size: 12px;'>车辆最近更新时间："+ time +"</p>"
      }));
      
      this.popupList.push(popup)
      this.allLayer.push(markerTo)
    },
  },
};
</script>
<style lang="scss" scoped>
html,
body {
  height: 100vh;
}

#map {
  width: 100%;
  height: 100vh;
}
</style>
